import React, { useEffect, useRef } from "react";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import SEO from "../../components/seo";
import Hero from "../../components/hero";
import toolImage from "../../images/interactive-tool-image_NA.jpg";
import background from "../../images/header-image-03.png";

const ApprovedProducts = ({ location }) => {
	let scrollRef = useRef();
	let scrollRef2 = useRef();
	let scrollRef3 = useRef();
	let scrollRef4 = useRef();
	let curPath = undefined;

	let runAfterRender = () => {
		const myElem = document.getElementById("myElem")
		if (myElem) {
			//do important stuff
		}
	}

	/* Auto-scroll to Hash Link (from a different Page) */
	useEffect(
		() => {
			var hash = location.hash;
			//console.log("Hash: " + hash);
			if (hash !== "") {
				var _this = this;
				window.requestAnimationFrame(function () {
					setTimeout(function () {
						var hashJump = hash.replace("#", "").replace("%20", " ");
						var top = 0;
						var bodyTop = document.querySelector('body').getBoundingClientRect().top;
						if (hashJump === 'Auryxia') {
							top = scrollRef.current.getBoundingClientRect().top;
						} else if (hashJump === 'Vafseo') {
							top = scrollRef3.current.getBoundingClientRect().top;
						} else if (hashJump === 'clinical-programs') {
							top = scrollRef2.current.getBoundingClientRect().top;
						} else {
							top = 0;
						}
						setTimeout(function () {
							var y = top - bodyTop - 230;
							//console.log("Y: " + y);
							window.scrollTo({ behavior: 'smooth', top: y });
						}, 200);
						//
						/* Intercept Footer Hash Links, Prevent Default, and Scroll to Hash */
						curPath = location.pathname;
						//console.log("curPath: " + curPath);
						var hashLink = document.querySelectorAll('.footer-hash');
						for (var i = 0; i < hashLink.length; ++i) {
							var ref = hashLink[i];
							ref.addEventListener('click', function (e) {
								var link = this.getAttribute('href');
								if (link.indexOf('#') > 0) {
									var parts = link.split('#');
									if (parts[0] === curPath) {
										e.preventDefault();
										var hashVal = parts[1];
										var hashRef = document.getElementById(hashVal);
										var bodyTop = document.querySelector('body').getBoundingClientRect().top;
										//console.log("Hash #: " + hashVal);
										setTimeout(function () {
											var top = hashRef.getBoundingClientRect().top;
											var y = top - bodyTop - 230;
											//console.log("Y hashLink: " + y);
											window.scrollTo({ behavior: 'smooth', top: y });
										}, 200);
										//console.log("Hash Link is on SAME PAGE");
									}
								}
							});
						}
					}, 20)
				});
			} else {
				setTimeout(function () {
					var y = 0 - 230;
					//console.log("Y hashLink: " + y);
					window.scrollTo({ behavior: 'instant', top: y });
				}, 50);
			}
		}, [location]
	);

	return (
		<Layout contact>
			<SEO title="Approved Products" />
			<Hero background={background}>
				<div className="col-sm-6">
					<h1 className="hero-heading" ref={scrollRef4} id="approved-products">Approved Products</h1>
					<p className="hero-copy">
						As part of our mission, we’re working to address complications of
						kidney disease. That's why we work to leverage our scientific
						expertise and innovative thinking to develop clinical advances in
						areas that are important to those living with CKD.
					</p>
				</div>
			</Hero>
			{/* Start Breadcrumbs */}
			<div className="section section-noPad">
				<div className="container breadcrumbs">
					<div className="row">
						<div className="col">
							<div className="crumb-container">
								<Link
									to="/"
								>
									Home
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/our-science/kidney-disease"
									className=""
								>
									Science
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/our-science/approved-products"
									className="active"
								>
									Approved Products
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrumbs */}
			<div className="section">
				<div className="container">
					<div className="row">
						<div className="col-sm-6">
							<h3 ref={scrollRef} id="Auryxia" className="drug-name">
								Auryxia<sup>&reg;</sup> (ferric citrate)
							</h3>
							<p className="pronunciation">pronounced ah-RICKS-ee-ah</p>
							{/*
							<ul>
								<li>
									asdf
								</li>
							</ul> */}
							<p><strong><a href="https://www.auryxia.com/wp-content/uploads/Auryxia_PI.pdf" target="_blank">Prescribing Information</a></strong></p>
						</div>
						<div className="col-sm-6">
							<h3 ref={scrollRef3} id="Vafseo" className="drug-name">
								Vafseo<sup>&reg;</sup> (vadadustat)
							</h3>
							<p className="pronunciation">pronounced vahF-see-oh</p>
							<p><strong><a href="https://vafseohcp.com/pdf/prescribing-information.pdf" target="_blank">Prescribing Information</a></strong></p>
						</div>
					</div>

				</div>
			</div>
		</Layout>
	);
};

export default ApprovedProducts;
